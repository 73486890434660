<template>
  <main class="main">
    <section class="section">
      <div class="container">
        <div class="page__wrap">
          <aside class="page__sidebar">
            <div class="sidebar__header">
              <h1 class="sidebar__title">
                Escolha a categoria que deseja reciclar!
              </h1>

              <p class="sidebar__description">
                <a @click="info = true" title="" href="#">Clique aqui</a>
                para saber mais informações sobre os materiais recicláveis.
              </p>
            </div>
          </aside>

          <div class="page__content">
            <div class="store__list">
              <div v-for="item in items" :key="item.id" class="store__item" :style="'--bcolor: ' + item.color" @click="openModal(item)">
                <div class="store__anchor" :title="item.title">
                  <i :class="'fa fa-2x fa-'+item.icon" v-if="item.icon"></i>
                  <h3 class="store__item--title">{{ item.title }}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <modal-store v-if="info == false" :modal.sync="modal" :selectedItem.sync="selectedItem" @result="resultOpen" @change-modal="e => (modal = e)"></modal-store>
    <modal-info v-if="info" :modal.sync="info" @change-modal="e => (info = e)"></modal-info>
    <modal
        :modal.sync="collectModal"
        @change-modal="e => (collectModal = e)"
        :selectedMaterial.sync="selectedMaterial"
      />
</main>
</template>

<script>
import axios from 'axios'

export default {
  data: () => ({
    modal: false,
    info: false,
    items: [],
    selectedItem: {},
    collectModal: false,
    selectedMaterial: {}
  }),
  components: {
    modalStore: () => import('./components/modal-store'),
    modalInfo: () => import('./components/modal_info'),
    Modal: () => import('@/components/request-collection.vue')
  },
  async mounted () {
    await this.getCategories()
  },
  methods: {
    async getCategories () {
      const response = await axios.get('/categories/')
      this.items = response.data.data
    },
    openModal (item) {
      this.selectedItem = item
      this.modal = true
    },
    resultOpen (val) {
      console.log('ress', val)
      this.selectedMaterial = {
        item_id: val.data.data.item_id,
        id: val.data.data.id,
        not_recyclable: null,
        recyclable_items: null
      }
      this.collectModal = true
    }
  }
}
</script>
